import React, { useState } from "react";
import Slider from "./components/Slider";
import InitialBanner from "./components/InitialBanner";




const LearningUnits = () => {
  const [isBrowseUnits, setIsBrowseUnits] = useState(false);

    function browseUnitsClick() {
        setIsBrowseUnits(true);
    }


    return (
    <div style={{height:"100%", width:"100%", margin:0}}>
      <div
           style={{height:"100%", width:"100%", margin:0, display:"grid", gridColumn:"1", gap:4, justifyItems:"center"}}
      >
        <div
          style={{
            textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            width:"100%",
              height:"100%",
              position:"relative",
              textAlign:"center",
              color:"white",
              fontSize:"15vh"
          }}
        >
          <div
              style={{
                fontFamily: "Poppins, sans-serif",
                textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
                textAlign: "center", // Align the text content
                position:"relative",
                  width:"100%",
                  height:"100%",
              }}>
            {isBrowseUnits && (<Slider isTeacher={false}/>)}
            {!isBrowseUnits && (<InitialBanner isTeacher={false} browseUnits={browseUnitsClick}/>)}
              </div>
        </div>
      </div>
    </div>
  );
};
export default LearningUnits;
