import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import videoIcon from "../../svg/video-icon-white.png";
import greenVideoIcon from "../../svg/video_selected.png";
import downloadIcon from "../../svg/download-icon-white.png";
import selectedRectangle from "../../svg/selected_rectangle.png";
import greenCheckMark from "../../svg/green_checkmark.png";

const FileItem = (props) => {

    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        setIsSelected(props.isSelected);
    },[props.isSelected]);
    function selectFile(){
        props.select(props.id);
    }


    return (
        <>
            {isMobile && (<div key={props.index} style={{marginLeft:0,position: 'relative' }} onClick={selectFile}>
                <div style={{
                    position: 'relative',
                    backgroundColor: 'rgba(29, 62, 77, 0.9)',
                    borderRadius: '30px',
                    padding: '2%',
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:"space-between"
                }}>
                    {isSelected && (
                        <div style={{
                            float: 'left',
                            left: 0,
                            marginLeft: '0.02%',
                            height: '100%',
                            position: 'absolute',
                            paddingTop: '2.5%',
                            paddingBottom: '2.5%'
                        }}>
                            <img alt="Green rectangle" src={selectedRectangle} />
                        </div>
                    )}
                    <div style={{ width: '10%', height: '100%', float: 'left', padding: '1%', position: 'relative' }}>
                        <div style={{ float: 'right', width: '90%' }}>
                            <img alt="Video icon" style={{ width: '100%', height: 'auto', position: 'relative' }} src={isSelected ? greenVideoIcon : videoIcon} />
                        </div>
                    </div>
                    <div style={{ width: '80%', float: 'left', position: 'relative',  maxWidth: '70%' }}>
                        <p style={{ fontSize: '1rem', marginBottom: 0, color: 'rgba(255, 255, 255, 1)', textAlign: 'left' }}>{props.name}</p>
                    </div>
                    <div style={{ width: '10%', float: 'right', position: 'relative', marginRight: 0, height: 'fit-content' }}>
                        {props.type !==  "h5p" && props.material?.seen ===  false &&  (
                            <div>
                                <img alt="Download icon" src={downloadIcon} style={{ width: '30px', height: '30px' }} />
                            </div>
                        )}
                        { props.material?.seen ===  true && (
                            <div>
                                <img alt="Green checkmark" src={greenCheckMark} style={{ width: '30px', height: 'auto' }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>)}
            {!isMobile && (
                <div key={props.index} style={{margin: '1.5rem', marginLeft:0,position: 'relative', marginTop:0, marginBottom:0 }} onClick={selectFile}>
                    <div style={{
                        position: 'relative',
                        backgroundColor: 'rgba(29, 62, 77, 0.9)',
                        borderRadius: '30px',
                        padding: '2%',
                        width: '100%',
                        height: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:"space-between"
                    }}>
                        {isSelected && (
                            <div style={{
                                float: 'left',
                                left: 0,
                                marginLeft: '0.02%',
                                height: '100%',
                                position: 'absolute',
                                paddingTop: '2.5%',
                                paddingBottom: '2.5%'
                            }}>
                                <img alt="Green rectangle" src={selectedRectangle} />
                            </div>
                        )}
                        <div style={{ width: '10%', height: '100%', float: 'left', padding: '1%', position: 'relative' }}>
                            <div style={{ float: 'right', width: '90%' }}>
                                <img alt="Video icon" style={{ width: '100%', height: 'auto', position: 'relative' }} src={isSelected ? greenVideoIcon : videoIcon} />
                            </div>
                        </div>
                        <div style={{ width: '80%', float: 'left', position: 'relative',  maxWidth: '70%' }}>
                            <p style={{ fontSize: '1rem', marginBottom: 0, color: 'rgba(255, 255, 255, 1)', textAlign: 'left' }}>{props.name}</p>
                        </div>
                        <div style={{ width: '10%', float: 'right', position: 'relative', marginRight: 0, height: 'fit-content' }}>
                            {props.type !==  "h5p" && props.material?.seen ===  false &&  (
                                <div>
                                    <img alt="Download Icon" src={downloadIcon} style={{ width: '30px', height: '30px' }} />
                                </div>
                            )}
                            { props.material?.seen ===  true && (
                                <div>
                                    <img alt="Green Checkmark" src={greenCheckMark} style={{ width: '30px', height: 'auto' }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}

export default FileItem;
