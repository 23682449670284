import "../../css/home-page-users.css"
import FilesIcon from "./FilesIcon";
import {useTranslation} from "react-i18next";
import whiteCheckMark from "../../svg/white_checkmark.png"
import {useEffect, useState} from "react";


const DecorativeBar = (props) => {
    const [t,] = useTranslation("global");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            {isMobile && (
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "2vh",
                            height: "auto",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ float: "left", width: "auto", display: "flex", flexDirection: "row" }}>
                            <FilesIcon files_number={props.files_number} />
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "10%" }}>
                                {t('decorative_bar.files')}
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1, position: "relative", marginLeft: "5%" }}>
                            <div style={{
                                position: "absolute",
                                top: "-20px", // Adjust the top position as needed
                                fontSize: "1.5vh",
                                left: props.numberOfItemsSeen === props.files_number ? `calc(${props.numberOfItemsSeen / Object.entries(props.files).length * 100}% - 10vw)` : `calc(${props.numberOfItemsSeen / Object.entries(props.files).length * 100}%)`,
                                whiteSpace: "nowrap"
                            }}>
                                <div style={{display:"flex"}}>
                                    <div>
                                        {props.numberOfItemsSeen} { props.numberOfItemsSeen>1 || props.numberOfItemsSeen=== 0? t('decorative_bar.files_seen'):t('decorative_bar.file_seen')}.

                                    </div>
                                    <div style={{marginLeft:"0.5vw"}}>
                                        <img
                                            src={whiteCheckMark}
                                            alt="Checkmark"
                                            style={{ width: "20px", height:"auto"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginLeft: "3%",
                                    height: "4px",
                                    backgroundColor: "rgba(29, 62, 77,1)",
                                    float: "right",
                                    flex: "1",
                                    position: "relative",
                                    borderRadius: "2px"
                                }}
                            >
                                <div
                                    style={{
                                        height: "75%",
                                        width: `${props.numberOfItemsSeen / Object.entries(props.files).length * 100}%`,
                                        backgroundColor: "rgba(0, 230, 118,1)",
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translateY(-50%)"
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!isMobile && (
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "2vh",
                            height: "auto",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ float: "left", width: "auto", display: "flex", flexDirection: "row" }}>
                            <FilesIcon files_number={props.files_number} />
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "10%" }}>
                                {t('decorative_bar.files')}
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1, position: "relative" }}>
                            <div style={{
                                position: "absolute",
                                top: "-20px", // Adjust the top position as needed
                                fontSize: "1.5vh",
                                left: props.numberOfItemsSeen === props.files_number ? `calc(${props.numberOfItemsSeen / Object.entries(props.files).length * 100}% - 10vw)` : `calc(${props.numberOfItemsSeen / Object.entries(props.files).length * 100}%)`,
                                whiteSpace: "nowrap"
                            }}>
                                <div style={{display:"flex"}}>
                                    <div>
                                        {props.numberOfItemsSeen} { props.numberOfItemsSeen>1 || props.numberOfItemsSeen=== 0? t('decorative_bar.files_seen'):t('decorative_bar.file_seen')}.

                                    </div>
                                    <div style={{marginLeft:"0.5vw"}}>
                                        <img
                                            src={whiteCheckMark}
                                            alt="Checkmark"
                                            style={{ width: "20px", height:"auto"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginLeft: "3%",
                                    height: "4px",
                                    backgroundColor: "rgba(29, 62, 77,1)",
                                    float: "right",
                                    flex: "1",
                                    position: "relative",
                                    borderRadius: "2px"
                                }}
                            >
                                <div
                                    style={{
                                        height: "75%",
                                        width: `${props.numberOfItemsSeen / Object.entries(props.files).length * 100}%`,
                                        backgroundColor: "rgba(0, 230, 118,1)",
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translateY(-50%)"
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>

    );
}

export default DecorativeBar;
