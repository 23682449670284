import "../../css/stem-input.css"
import InnerSearchItem from "./InnerSearchItem";
import {useEffect, useState} from "react";
import backButton from "../../svg/backButton.png";

const SearchItem = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const setIsSearching = () => {
        if (props.isSearching){
            props.closeSearchItems();
            props.setIsSearching(false);
            props.setIsMainViewActive(true);
        }else{
            props.setIsSearching(true);
        }
    }

    function setId(id){
        props.setId(id);
    }

    return (
        <>
            {isMobile && (
                <>
                    <div
                        style={{
                            marginTop: 50,
                            width: "90%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            maxHeight: "50vh",
                            overflowY: "scroll",
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            {props.selectedMaterials.map((material, index) => (
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <InnerSearchItem key={material.id} index={index} material={material} files={material.files} setId={setId}/>
                                    <div style={{marginTop:"5%"}}/>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 10, // Adjust this value as needed
                            position: "relative",
                            display: "block",
                            justifyContent: "center",
                        }}
                    >
                        <button className="slider-button-inner-search-exit" onClick={setIsSearching}>
                            <img
                                src={backButton}
                                alt="Back button"
                                style={{ width: "100%"}}
                            />
                        </button>
                    </div>
                </>
            )}
            {!isMobile && (<>
                <div
                    style={{
                        marginTop: 50,
                        width: "50%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        maxHeight: "60vh",
                        overflowY: "scroll",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        {props.selectedMaterials.map((material, index) => (
                            <div>
                                <InnerSearchItem key={material.id} index={index} material={material} files={material.files} setId={setId}/>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 20, // Adjust this value as needed
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button className="slider-button-inner-search-exit" onClick={setIsSearching}>
                        <img
                            src={backButton}
                            alt="Back button"
                            style={{ width: "100%"}}
                        />
                    </button>
                </div>
            </>)}
        </>


    );
}

export default SearchItem;
