import en_flag from "../svg/flag-of-the-united-kingdom-flag-of-great-britain-flag-of-england-png-favpng-q7ZWJzpaYN34ZfrVGRepJsUKN_t.jpg";
import de_flag from "../svg/flag-of-germany-german-language-flag-of-france-png-favpng-MpxaHGB8QjKfMPjAYd3fEyvYm.jpg";
import el_flag from "../svg/greece.png";
import it_flag from "../svg/italy.png";
import ro_flag from "../svg/romania.png";
import tr_flag from "../svg/tukrey.png";


export const LANGUAGES = [
  {
    key: "en",
    value: "en",
    image: {
      avatar: true,
      src: en_flag,
    },
  },
  {
    key: "de",
    //   text: "de",
    value: "de",
    image: {
      avatar: true,
      src: de_flag,
    },
  },
  {
    key: "el",
    value: "el",
    image: {
      avatar: false,
      src: el_flag,
    },
  },
  {
    key: "it",
    value: "it",
    image: {
      avatar: false,
      src: it_flag,
    },
  },
  {
    key: "ro",
    value: "ro",
    image: {
      avatar: false,
      src: ro_flag,
    },
  },
  {
    key: "tr",
    value: "tr",
    image: {
      avatar: false,
      src: tr_flag,
    },
  },
];
