import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import "../../css/home-page-users.css"
import backButton from "../../svg/backButton.png"
import FileItem from "./FileItem";
import DecorativeBar from "./DecorativeBar";
import {useTranslation} from "react-i18next";
import greenDownloadIcon from "../../svg/download-icon-green.png";
import '../../css/files.css';



const Files = (props) => {
    const [t, ] = useTranslation("global");
    const [, setSelectedMedia] = useState("");
    const [selectedTitle, setSelectedTitle] = useState(props.materials[props.selectedId].title);
    const [selectedDescription, setSelectedDescription] = useState(props.materials[props.selectedId].description);
    const [selectedName, setSelectedName] = useState(props.materials[props.selectedId].name);
    const [, setSelectedImage] = useState(props.materials[props.selectedId].files[0].path);
    const [selectedId, setSelectedId] = useState(props.selectedId);
    const [, setSelectedFile] = useState();
    const [, setSelectedFileHP5] = useState(props.materials[props.selectedId].files[0].h5p);
    const [files, setFiles] = useState(props.materials);
    const [numberOfItemsSeen, setNumberOfItemsSeen] = useState(0);
    const [unitData, setUnitData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // Function to handle saving data to localStorage
    const saveDataToLocalStorage = (updatedItems) => {
        localStorage.setItem(props.unitId, JSON.stringify(updatedItems));
    };



    useEffect(() => {
        const savedData = localStorage.getItem(props.unitId);
        if (savedData) {
            setUnitData(JSON.parse(savedData));
            setNumberOfItemsSeen(JSON.parse(savedData).length);
            Object.keys(JSON.parse(savedData)).forEach(function(index) {
                let files_temp = files;
                if (files_temp[JSON.parse(savedData)[index]]) {
                    files_temp[JSON.parse(savedData)[index]].seen = true;
                    setFiles(files_temp);
                }
            });

        }
    }, [props.unitId] );

    const addItemToSeen = (id) => {
        if (!unitData.includes(id)) {
            const updatedItems = [...unitData, id];
            setUnitData(updatedItems);
            saveDataToLocalStorage(updatedItems);
            setNumberOfItemsSeen(updatedItems.length);
        }
        let files_temp = files;
        if (files_temp[id]) {
            files_temp[id].seen = true;
            setFiles(files_temp);
        }
    };


    function select(id){
        Object.entries(files).forEach((file)=>{
            file.isSelected = false;
        });
        files[id].isSelected = true;
        setSelectedId(id);
        setSelectedTitle(files[id].title);
        setSelectedName(files[id].name);
        setSelectedMedia(files[id].media);
        setSelectedDescription(files[id].description);
        setSelectedImage(files[id].files[0].path ?? '');
        setSelectedFile(files[id].file);
        setSelectedFileHP5(files[id].hp5);
        if (files[id].files[0].path == null){
            addItemToSeen(id);
        }
    }

    const FilePreview = () => {
        let id = selectedId;
        const fileType = files[id].files[0].type;

        switch (fileType) {
            case 'image':
                return <ImagePreview file={process.env.REACT_APP_BACKEND_URL + files[id].files[0].path}/>;
            case 'pdf':
                // Render a document preview component
                return <NoPreview file={files[id].files[0]}  type={'pdf'}/>;
            case 'word':
                // Render a document preview component
                return <NoPreview file={files[id].files[0]} type={'docx'}/>;
            case 'video':
                // Render a video preview component
                return <VideoPreview file={files[id].files[0].h5p}/>;
            case 'powerpoint':
                return <NoPreview file={files[id].files[0]} type={'pptx'}/>;
            case 'h5p':
                return  <H5PPreview file={files[id].files[0].h5p}/>;
            default:
                // Render a generic preview for unknown file types
                return <div>{t('files.no_preview')}</div>;
        }
    };

    const H5PPreview = ({ file }) => {
        return (
            <>
                <div className="h5p-preview-title">
                    {selectedTitle}
                </div>
                <div className="h5p-preview-description">
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div className="h5p-preview-container">
                            <div className="h5p-preview-box">
                                <div
                                    className="h5p-preview-content"
                                    dangerouslySetInnerHTML={{ __html: file }}
                                />
                            </div>
                        </div>
                        <div className="h5p-preview-selected-name">
                            {selectedName}
                        </div>
                    </>
                )}
            </>
        );
    };

    const ImagePreview = ({ file }) => {
        return (
            <>
                <div className="image-preview-title">
                    {selectedTitle}
                </div>
                <div className="image-preview-description">
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div className="image-preview-container">
                            <div className="image-preview-box">
                                <div className="image-preview-img-wrapper">
                                    <img
                                        className="image-preview-img"
                                        src={file}
                                        alt="Selected item"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="image-preview-selected-name">
                            {selectedName}
                        </div>
                    </>
                )}
            </>
        );
    };


    const VideoPreview = ({ file }) => {
        return (
            <>
                <div className="video-preview-title">
                    {selectedTitle}
                </div>
                <div className="video-preview-description">
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div className="video-preview-container">
                            <div className="video-preview-box">
                                <div
                                    className="video-preview-embed"
                                    dangerouslySetInnerHTML={{ __html: file }}
                                />
                            </div>
                        </div>
                        <div className="video-preview-selected-name">
                            {selectedName}
                        </div>
                    </>
                )}
            </>
        );
    };


    const NoPreview = ({ file, type }) => {
        return (
            <>
                {isMobile ? (
                    <>
                        <div className="no-preview-title">
                            {selectedTitle}
                        </div>
                        <div className="no-preview-description">
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <div className="no-preview-mobile-container">
                                <div className="no-preview-box">
                                    <div className="no-preview-message-container">
                                        <div>
                                            <div className="no-preview-text">
                                                {t("files.no_preview")}
                                            </div>
                                            <div className="no-preview-download-button">
                                                <button className="download-button">
                                                    <a
                                                        href={process.env.REACT_APP_BACKEND_URL + "/api/download-file/" + file?.id}
                                                        download={selectedName}
                                                        onClick={() => addItemToSeen(selectedId)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "rgb(0, 231, 110,1)" }}
                                                    >
                                                        <div className="no-preview-download-button-content">
                                                            <div style={{ display: "flex", marginRight: "2vw" }}>
                                                                <p>{t("slider.download")}</p>
                                                            </div>
                                                            <div className="no-preview-download-icon">
                                                                <img alt="Download icon" src={greenDownloadIcon} />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="no-preview-file-name">
                                    {selectedName}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="no-preview-title">
                            {selectedTitle}
                        </div>
                        <div className="no-preview-description">
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <div className="no-preview-desktop-container">
                                <div className="no-preview-box">
                                    <div className="no-preview-message-container">
                                        <div>
                                            <div className="no-preview-text">
                                                {t("files.no_preview")}
                                            </div>
                                            <div className="no-preview-download-button">
                                                <button className="download-button">
                                                    <a
                                                        href={process.env.REACT_APP_BACKEND_URL + "/api/download-file/" + file?.id}
                                                        download={selectedName}
                                                        onClick={() => addItemToSeen(selectedId)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "rgb(0, 231, 110,1)" }}
                                                    >
                                                        <div className="no-preview-download-button-content">
                                                            <div style={{ display: "flex", marginRight: "2vw" }}>
                                                                <p>{t("slider.download")}</p>
                                                            </div>
                                                            <div className="no-preview-download-icon">
                                                                <img alt="Download icon" src={greenDownloadIcon} />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="no-preview-file-name">
                                    {selectedName}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {isMobile && (
                <div className="files-container">
                    <div className="files-wrapper">
                        <div className="file-preview-container">
                            <FilePreview />

                            {!selectedName && (
                                <div className="no-items-container">
                                    There are no items.
                                </div>
                            )}
                        </div>
                        <div className="grid-container">
                            <div className="files-grid">
                                <DecorativeBar
                                    numberOfItemsSeen={numberOfItemsSeen}
                                    files={files}
                                    files_number={Object.entries(files).length}
                                />

                                <div className="files-scrollview">
                                    {Object.entries(files).map(([id, material]) => (
                                        <div key={id}>
                                            <FileItem
                                                id={material.id}
                                                file={material.files[0]}
                                                material={material}
                                                index={id} // You can use id here if it's unique
                                                isSelected={material.id === selectedId}
                                                name={material.name}
                                                path={material.files[0].path}
                                                type={material.files[0].type}
                                                select={select}
                                                addItemToSeen={addItemToSeen}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-button-container">
                        <button className="slider-button-inner-search-exit" onClick={props.exit}>
                            <img
                                src={backButton}
                                alt="Back button"
                            />
                        </button>
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="files-container">
                    <div className="files-wrapper">
                        <div className="file-preview-container desktop">
                            <FilePreview />

                            {!selectedName && (
                                <div className="no-items-container">
                                    There are no items.
                                </div>
                            )}
                        </div>
                        <div className="grid-container desktop">
                            <div className="files-grid desktop">
                                <DecorativeBar
                                    numberOfItemsSeen={numberOfItemsSeen}
                                    files={files}
                                    files_number={Object.entries(files).length}
                                />

                                <div className="files-scrollview">
                                    {Object.entries(files).map(([id, material]) => (
                                        <div key={id}>
                                            <FileItem
                                                id={material.id}
                                                file={material.files[0]}
                                                material={material}
                                                index={id} // You can use id here if it's unique
                                                isSelected={material.id === selectedId}
                                                name={material.name}
                                                path={material.files[0].path}
                                                type={material.files[0].type}
                                                select={select}
                                                addItemToSeen={addItemToSeen}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-button-container">
                        <button className="slider-button-inner-search-exit" onClick={props.exit}>
                            <img
                                src={backButton}
                                alt="Back button"
                            />
                        </button>
                    </div>
                </div>
            )}
        </>



    );
}

export default Files;
