import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import background from "../svg/teacher-background.png";
import axios from "axios";
import blueDownloadIcon from "../svg/download-icon-blue.png";
import Loader from "./components/Loader";
import "../css/TeacherMaterials.css";




const TeacherMaterials = () => {
  const [t, i18n] = useTranslation("global");
  const [, ] = useState(true);
  const [, setIsMobile] = useState(window.innerWidth <= 970);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [, setSearchText] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field




    useEffect(() => {
        // Fetch data from API
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/teacher-materials/'+i18n.language)
            .then(response => {
                setItems(response.data);
                setMaterials(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data from API', error);
            });
    }, [i18n.language]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    function searchBarChange(event) {
        setSearchText(event.target.value);
        selectMaterials(event.target.value);

    }

    function selectMaterials(text){
        let temp_list = [];
        if (materials !== null && materials.length >0){
            materials.map(material => {
                if (
                    (text !== '' || text !== ' ') &
                    material.name.toLowerCase().includes(text.toLowerCase()) ||
                    material.title.toLowerCase().includes(text.toLowerCase()) ||
                    material.description.toLowerCase().includes(text.toLowerCase())
                ) {
                    temp_list.push(material);
                }
            });
            if(text === '' || text === ' '){
                temp_list.push(materials)
            }
            setItems(temp_list);
        }
    }

    return (
        <div className="App mx-auto">
            {(
                <>
                {isLoading ? (<div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>): (
                    <div
                        style={{
                            height: "100vh",
                            backgroundImage: `url(${background})`, // Set background image
                            backgroundSize: "cover",
                            backgroundPosition: "left",
                            backgroundRepeat: "repeat",
                            width: "100%",
                            position: "fixed",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="teacher-content-title">
                            {/* Title */}
                            <h1 className="teacher-content-title">
                                {t("teacher.teacher_materials")}
                            </h1>
                        </div>
                        <div
                            className="teacher-content"
                        >

                            {/* Search Bar */}
                            <>
                                <input
                                    ref={inputRef} // Assign the ref to the input field
                                    className="stem-input"
                                    type="text"
                                    id="username"
                                    name="username"
                                    placeholder={t("slider.search_placeholder")}
                                    onChange={searchBarChange}
                                    style={{
                                        height: "7vh",
                                        width: "80%",
                                        fontSize: "2vh"
                                    }}
                                />
                            </>

                            {/* Materials Grid */}
                            <div
                                className={"teacher-content-grid"}
                            >
                                {/* Example of Material Items */}
                                {items && items.length>0 && items.map((item) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            background: "#fff",
                                            padding: "20px",
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {/* Display item properties */}
                                        <div style={{width: "50%"}}>
                                            <b><p style={{
                                                fontSize: "2vh",
                                                marginBottom: 0,
                                                color: "rgba(29, 62, 77,0.9)",
                                                textAlign: "left"
                                            }}>{item.title}</p></b>
                                            <p style={{
                                                fontSize: "1.5vh",
                                                marginTop: 0,
                                                paddingTop: 0,
                                                color: "rgba(29, 62, 77,0.9)",
                                                textAlign: "left"
                                            }}>{item.description}</p>
                                        </div>

                                        {/* Download link */}
                                        {item.files && item.files.map(file => (
                                            <a
                                                key={file.id}
                                                href={`${process.env.REACT_APP_BACKEND_URL}/api/download-file/${file.id}`}
                                                download={item.title}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{color: "rgb(0, 231, 110, 1)"}}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    flex: 1,
                                                    width: "100%"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <img src={blueDownloadIcon} alt="Download Icon"
                                                             style={{width: '30px', height: '30px'}}/>
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                )}
                </>
            )}
        </div>
    );
};
export default TeacherMaterials;
