import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import background from "../svg/contact-us-background.png";
import ContactForm from "./components/ContactForm";




const About = (props) => {
  const [t, ] = useTranslation("global");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
      <div className="App mx-auto">
          {!isMobile && (<>
              <div style={{
                  height:"100vh",
                  backgroundImage: `url(${background})`, // Set background image
                  backgroundSize:"cover",
                  backgroundPosition: "left",
                  backgroundRepeat: "repeat",
                  width:"100%",
                  position:"fixed",
              }}>


                  <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "center",
                      alignItems: "center",
                      position:"relative",
                      color:"white",
                      right:0,
                      width:"50vw",
                      float:"right",
                      height:"100vh",
                      maxHeight:"200vh",
                      paddingLeft:"5%",
                      paddingRight:"5%",
                      top:"30vh"

                  }}>

                      <div style={{ alignItems: "center", fontSize: "5vh"}}>
                          <div style={{
                              display:"flex"
                          }}>
                              <div style={{
                                  display:"flex",
                                  marginRight:"10%"
                              }}>
                                  {t("contacts.title")}
                              </div>
                              <div style={{
                                  display:"flex", flex:1, alignItems:"center", width:"100vw"
                              }}>
                                  <div style={{ width: "100%", height: "2px", backgroundColor: "white", position:"absolute" }}></div>
                              </div>
                          </div>
                          <div style={{
                              alignItems: "start",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "2vh",
                              marginRight: "20vh",
                              width:"100%",
                              left:0
                          }}>
                              <div
                                  style={{
                                      textAlign:"justify",
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1.7vh",
                                      marginTop: "3vh",
                                      left:0
                                  }}
                              >
                                  <ContactForm/>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </>)}
          {isMobile && (<>
              <div style={{
                  height:"100vh",
                  backgroundImage: `url(${background})`, // Set background image
                  backgroundSize:"cover",
                  backgroundRepeat: "repeat",
                  width:"100%",
                  position:"fixed",
              }}>
                  <div style={{
                      height:"100vh",
                      background:"rgba(0,0,0,0.6)",
                      width:"100%",
                      position:"fixed",
                  }}></div>


                  <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "center",
                      alignItems: "center",
                      position:"relative",
                      color:"white",
                      width:"100vw",
                      height:"100vh",
                      maxHeight:"200vh",
                      paddingLeft:"5%",
                      paddingRight:"5%",
                      top:"15vh"

                  }}>

                      <div style={{ fontSize: "5vh", display:"flex", flexDirection:"column", alignItems:"center"}}>
                          <div style={{
                              display:"flex"
                          }}>
                              <div style={{
                                  display:"flex",
                              }}>
                                  {t("contacts.title")}
                              </div>
                          </div>
                          <div style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "2vh",
                              width:"100%",
                          }}>
                              <div
                                  style={{
                                      textAlign:"justify",
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1.7vh",
                                      marginTop: "3vh",
                                      display:"flex", flexDirection:"column", alignItems:"center"
                                  }}
                              >
                                  <ContactForm/>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </>)
          }
      </div>
  );
};

export default About;
