import React from "react";
import erasmusLogo from "../../svg/erasmus-logo.png";
import "../../css/home-page-users.css";
import "../../css/footer.css"

const Footer = (props) => {

  return (
      <>
        <div className="outside-div">
          <div className="inner-div">
              <div className="image-div">
                <img src={erasmusLogo} alt="logo" />
              </div>
          </div>
        </div>
      </>




  );
};

export default Footer;
