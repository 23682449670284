import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import background from "../svg/about-us-bg.png";
import {Link} from "react-router-dom";




const About = (props) => {
  const [t,] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleSetCurrentPage(page) {
        props.setCurrentPage(page);
    }

    return (
        <>
            {!isMobile && (
                <>
                    <div className="App mx-auto">
                        <div style={{
                            height:"100vh",
                            backgroundImage: `url(${background})`, // Set background image
                            backgroundSize:"cover",
                            backgroundPosition: "left",
                            backgroundRepeat: "repeat",
                            width:"100%",
                            position:"fixed",
                        }}>


                            <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                                fontFamily: "Poppins, sans-serif",
                                textAlign: "center",
                                alignItems: "center",
                                position:"relative",
                                color:"white",
                                right:0,
                                width:"50vw",
                                float:"right",
                                height:"100vh",
                                maxHeight:"200vh",
                                paddingLeft:"5%",
                                paddingRight:"5%",
                                top:"30vh"

                            }}>

                                <div style={{ alignItems: "center", fontSize: "5vh"}}>
                                    <div style={{
                                        display:"flex"
                                    }}>
                                        <div style={{
                                            display:"flex",
                                            marginRight:"10%"
                                        }}>
                                            {t("about.title")}
                                        </div>
                                        <div style={{
                                            display:"flex", flex:1, alignItems:"center", width:"100vw"
                                        }}>
                                            <div style={{ width: "100%", height: "2px", backgroundColor: "white", position:"absolute" }}></div>
                                        </div>
                                    </div>
                                    <div style={{
                                        alignItems: "start",
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "2vh",
                                        marginRight: "20vh",
                                        width:"100%",
                                        left:0
                                    }}>
                                        <div
                                            style={{
                                                textAlign:"justify",
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: "1.7vh",
                                                marginTop: "3vh",
                                                left:0
                                            }}
                                        >
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget magna fermentum iaculis eu. Velit egestas dui id ornare arcu odio ut. Enim sed faucibus turpis in eu mi bibendum neque. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Et malesuada fames ac turpis. Ipsum consequat nisl vel pretium lectus quam id. Quis blandit turpis cursus in hac habitasse. Placerat vestibulum lectus mauris ultrices eros in cursus turpis. Consectetur adipiscing elit duis tristique. Facilisis mauris sit amet massa vitae. Tellus id interdum velit laoreet id donec ultrices. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Risus ultricies tristique nulla aliquet enim tortor. Morbi tristique senectus et netus. Posuere ac ut consequat semper.
                                            </p>
                                            <div>
                                                <div style={{ width:"100%", justifyContent: 'space-between' }}>
                                                    <div style={{width:"50%", float:"left"}}>
                                                        <Link
                                                            to={"/"}
                                                            onClick={()=>{handleSetCurrentPage('learning-units')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%", paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("initial_banner.browse_units")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div style={{width:"50%" , float:"right"}}>
                                                        <Link
                                                            to={"/contacts"}
                                                            onClick={()=>{handleSetCurrentPage('contacts')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ float:"right", position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%",paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("about.contact_us")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div className="App mx-auto" style={{height:"100vh"}}>
                        <div style={{
                            height:"110vh",
                            backgroundImage: `url(${background})`, // Set background image
                            backgroundSize:"cover",
                            backgroundRepeat: "repeat",
                            width:"100%",
                            position:"fixed",
                        }}>
                            <div style={{
                                height:"100vh",
                                background:"rgba(0,0,0,0.6)",
                                width:"100%",
                                position:"fixed",
                            }}></div>


                            <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                                fontFamily: "Poppins, sans-serif",
                                textAlign: "center",
                                alignItems: "center",
                                position:"relative",
                                color:"white",
                                width:"100vw",
                                height:"100vh",
                                maxHeight:"200vh",
                                paddingLeft:"5%",
                                paddingRight:"5%",
                                top:"20vh"

                            }}>

                                <div style={{ alignItems: "center", fontSize: "5vh"}}>
                                    <div style={{
                                        display:"flex"
                                    }}>
                                        <div style={{
                                            display:"flex",
                                        }}>
                                            {t("about.title")}
                                        </div>

                                    </div>
                                    <div style={{
                                        alignItems: "center",
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "2vh",
                                        width:"100%",
                                    }}>
                                        <div
                                            style={{
                                                textAlign:"justify",
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: "1.7vh",
                                                marginTop: "3vh",
                                                left:0
                                            }}
                                        >
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget magna fermentum iaculis eu. Velit egestas dui id ornare arcu odio ut. Enim sed faucibus turpis in eu mi bibendum neque. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Et malesuada fames ac turpis. Ipsum consequat nisl vel pretium lectus quam id. Quis blandit turpis cursus in hac habitasse. Placerat vestibulum lectus mauris ultrices eros in cursus turpis. Consectetur adipiscing elit duis tristique. Facilisis mauris sit amet massa vitae. Tellus id interdum velit laoreet id donec ultrices. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Risus ultricies tristique nulla aliquet enim tortor. Morbi tristique senectus et netus. Posuere ac ut consequat semper.
                                            </p>
                                            <div>
                                                <div style={{ width:"100%", justifyContent: 'space-between' }}>
                                                    <div style={{width:"50%", float:"left"}}>
                                                        <Link
                                                            to={"/"}
                                                            onClick={()=>{handleSetCurrentPage('learning-units')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%", paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("initial_banner.browse_units")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div style={{width:"50%" , float:"right"}}>
                                                        <Link
                                                            to={"/contacts"}
                                                            onClick={()=>{handleSetCurrentPage('contacts')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ float:"right", position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%",paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("about.contact_us")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>


  );
};

export default About;
