import React, { useState } from 'react';
import '../../css/MobileMenu.css';
import logo_white from "../../svg/stemLogo.jpg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Language from "./Language"; // Import your CSS file for styling


const MobileMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [slideOutAnimation, setSlideOutAnimation] = useState(false);
    const [t, ] = useTranslation("global");

    const toggleMenu = () => {
            setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setSlideOutAnimation(true);
    };

    const handleAnimationEnd = () => {
        if (menuOpen && slideOutAnimation){
            setMenuOpen(!menuOpen);
            setSlideOutAnimation(false);
        }
    };


    function handleTabClick(index) {
        closeMenu();
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", overflowY:"clip" }}>
                <div className="menu-logo">
                    <img src={logo_white} alt="Logo" style={{width:"15%", borderRadius:"50%"}} />
                </div>
                <div className="menu-icon" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

        <div>
            <ul className={`menu-options ${menuOpen ? 'active' : ''} ${slideOutAnimation ? 'slide-out' : ''}`}
                onAnimationEnd={handleAnimationEnd}>

                <li>
                    <div style={{float: "left", height: "auto", width: "90%", marginBottom: "10%"}}><img
                        src={logo_white} alt="Logo" style={{width: "15%", borderRadius: "50%"}}/></div>
                    <div>
                        <button className="close-icon" onClick={closeMenu}></button>
                    </div>
                </li>
                <li><Link
                    href="#learningUnits"
                    to={"/"}
                    onClick={() => handleTabClick()}
                >{t("menu.learning_units")}
                </Link>
                </li>

                <li><Link
                    href="#teacherMaterials"
                    to={"/teacher-materials"}
                    onClick={() => handleTabClick()}
                >{t("menu.teacher_materials")}
                </Link>
                </li>

                <li><Link
                    href="#about"
                    to={"/about"}
                    onClick={() => handleTabClick()}
                >{t("menu.about")}</Link></li>
                <li><Link
                    href="#contacts"
                    to={"/contacts"}
                    onClick={() => handleTabClick()}
                >{t("menu.contacts")}</Link></li>
                <li>
                    <div style={{display: "flex", flex: 1}}>
                        <div>
                            <Language/>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        </>
    );
};

export default MobileMenu;
