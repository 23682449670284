import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import global_en from "./translation/en/global.json";
import global_de from "./translation/de/global.json";
import global_el from "./translation/el/global.json";
import global_it from "./translation/it/global.json";
import global_ro from "./translation/ro/global.json";
import global_tr from "./translation/tr/global.json";


import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter } from "react-router-dom";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    de: {
      global: global_de,
    },
    el: {
      global: global_el,
    },
    it: {
      global: global_it,
    },
    ro: {
      global: global_ro,
    },
    tr: {
      global: global_tr,
    },
  },
});

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <>
//       <App />
//   </>
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <BrowserRouter>
    <I18nextProvider i18n={i18n} defaultNS={"translation"}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
