import "../../css/home-page-users.css"
import fileIcon from "../../svg/file icon.png";


const FilesIcon = (props) => {


    return (
        <div
        >
            <div style={{ position: "relative", width: "5vh", display: "flex", alignItems: "center" }}>
                <img alt="File icon" src={fileIcon} style={{marginRight: "1%"}} />
                <div style={{ position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", padding:0 }}>
                    <div>
                        {props.files_number}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilesIcon;
