import React, {useEffect, useState} from 'react';
import bg from "../../svg/bg.png";
import backgroundPattern from "../../svg/background-pattern.png";
import {useTranslation} from "react-i18next";
import "../../css/home-page-users.css";



const InitialBanner = (props) => {
    const [t, ] = useTranslation("global");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile && (
                <div className="slider middle" style={{ width: '100%', height:'100%', marginTop:0, marginBottom:0}}>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign:"center"}}>
                        <div className="slider-button-learn-stem"
                             style={{ position: "absolute" , backgroundImage:backgroundPattern,textAlign:"center", pointerEvents:"none"}}>
                            <div style={{ left:0,position: "relative", marginBottom:"-2%"}}>
                                <p style={{ position: "relative", left:0, height:"auto", padding:"0 !important", margin:"0 !important", fontSize:"9vw"}}><b>{t("initial_banner.learn_stem")}</b></p>
                            </div>
                            <div style={{left:0, verticalAlign:"baseline"}}>
                                <p  style={{ position: "relative", fontSize:"2vh", alignItems:"start", left:0}}>{t("initial_banner.learning_units_portal")}</p>
                            </div>

                        </div>
                        <button className="slider-button-browse-units" style={{ position: "absolute"}} onClick={props.browseUnits}>
                            <p>{t("initial_banner.browse_units")}</p>
                        </button>
                    </div>
                    <div className="slides" >
                        <div className={`slide`} style={{ display: 'block' }}>
                            <img src={bg} alt={`Slide`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="slider middle" style={{ width: '100%', height:'100%', marginTop:0, marginBottom:0}}>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign:"center"}}>
                        <div className="slider-button-learn-stem"
                             style={{ position: "absolute" , backgroundImage:backgroundPattern,textAlign:"center", pointerEvents:"none"}}>
                            <div style={{ left:0,position: "relative", marginBottom:"-2%"}}>
                                <p style={{ position: "relative", left:0, height:"auto", padding:"0 !important", margin:"0 !important", fontSize:"7vw"}}><b>{t("initial_banner.learn_stem")}</b></p>
                            </div>
                            <div style={{left:0, verticalAlign:"baseline"}}>
                                <p  style={{ position: "relative", fontSize:"1.5vh", alignItems:"start", left:0}}>{t("initial_banner.learning_units_portal")}</p>
                            </div>

                        </div>
                        <button className="slider-button-browse-units" style={{ position: "absolute"}} onClick={props.browseUnits}>
                            <p>{t("initial_banner.browse_units")}</p>
                        </button>
                    </div>
                    <div className="slides" >
                        <div className={`slide`} style={{ display: 'block' }}>
                            <img src={bg} alt={`Slide`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default InitialBanner;
