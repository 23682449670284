import React, {useEffect, useState} from "react";
import LearningUnits from "./LearningUnits";
import Menu from "./components/Menu";
import { Route, Routes } from "react-router-dom";
import About from "./About";
import Contacts from "./Contacts";
import Footer from "./components/Footer";
import TeacherMaterials from "./TeacherMaterials";


const MainView = () => {
  const [changeVersionHandler, ] = useState(true);
  const [currentPage, setCurrentPage] = useState();
  let showFooter = true;


    useEffect(() => {
        // Get the current path and update the `currentPage` state
        const currentPath = window.location.pathname.replace("/", "");
        setCurrentPage(currentPath || "learning-units"); // Fallback to default if path is empty
    }, []);


  let background = {
    height:"100vh",
    width:"100vw",
    position:"relative",
  };


  return (
    <div id="home" style={background}>
      <Menu
        changeVersionHandler={changeVersionHandler}
        ticketsShow={!showFooter}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <Routes>
        <Route path="/" element={<LearningUnits setCurrentPage={setCurrentPage}/>} />
        <Route path="/teacher-materials" element={<TeacherMaterials setCurrentPage={setCurrentPage}/>} />
        <Route path="/about" element={<About setCurrentPage={setCurrentPage}/>} />
        <Route path="/contacts" element={<Contacts setCurrentPage={setCurrentPage}/>} />
      </Routes>
        <Footer/>
      {/*showFooter && <Footer show={false}/>*/}
    </div>
  );
};

export default MainView;
